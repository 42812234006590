import React from 'react'

import {AppWrap} from '../../wrapper'
import './Header.scss'

const Header = () => {
  return (
    <div className="app__header app__flex">
      <h1>Hi, I'm <span>Kenneth</span></h1>
    </div>
  )
}

export default AppWrap(Header, 'home')