import React from 'react'

import { AppWrap, MotionWrap } from '../../wrapper';
import './About.scss'
import { images } from '../../constants';

import {BsDownload} from 'react-icons/bs';
import Pdf from '../../Kenneth_Yi_Resume.pdf'

const About = () => {

  return (
    <>
      <h2 className="head-text">A Little Bit <span>About Me</span></h2>
      <div className="app__profiles">
        <div className="app__image">
          <img src={images.standingProfile} alt="me" />
        </div>
        <div className="app__paragraph">
          <p>
            Currently a student at the 
            University of Texas at Austin majoring in Computer Science. 
            <br />
            <br />
            Nice to meet you! My name is Kenneth and I am 20 years old living in 
            Austin, TX. I was born in Philadelphia, PA on June 24, 2002 and moved
            to Laredo, TX when I was 1 years old. Growing up, I always had a 
            strong urge of pushing my boundaries. I developed a passion in 
            coding at a young age and knew it was what I wanted to do. Throughout 
            middle and high school, I joined clubs such as Robotics and UIL to 
            learn and improve on my skills. I am now majoring in Computer Science 
            at UT Austin to further pursue my passion. 
            <br />
            <br />
            Besides school and work, I enjoy many different hobbies in my life. 
            Some of my hobbies include playing basketball, traveling, playing video 
            games, listening to music, and hanging out with friends.
            <br />
          </p>

          <button onClick={() => window.open(Pdf)}>
            <BsDownload/>  Resume
          </button>
        </div>
      </div>
      
    </>
  )
}

export default AppWrap(
  MotionWrap(About, 'app__about'),
     'about',
     'app__whitebg'
  );