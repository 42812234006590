import css from '../assets/css.png';
import html from '../assets/html.png';
import javascript from '../assets/javascript.png';
import node from '../assets/node.png';
import react from '../assets/react.png';
import logo from '../assets/logo.png';
import standingProfile from '../assets/standing.JPG';

export default {
  css,
  html,
  javascript,
  node,
  react,
  logo,
  standingProfile,
};