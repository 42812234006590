import React from 'react'
import {BsInstagram, BsLinkedin} from 'react-icons/bs';
import {GoMarkGithub} from 'react-icons/go';

const SocialMedia = () => {
  return (
    <div className="app__social">
        <div>
          <a href="https://github.com/ky5354">
            <GoMarkGithub />
          </a>
            
        </div>
        <div>
          <a href="https://www.linkedin.com/in/kenneth-yi/">
            <BsLinkedin />
          </a>
        </div>
        <div>
          <a href="https://www.instagram.com/southkoreanjesus/">
            <BsInstagram />
          </a>
        </div>
    </div>
  )
}

export default SocialMedia